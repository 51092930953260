import React from "react"

import { graphql, Link } from "gatsby"
import IndexLayout from '../layouts'
import Covers from "../components/Covers"

const GaleriesPage = ({ data }) => {
  return (
    <IndexLayout>
      <div className="hero is-primary">
        <div className="hero-body">
          <div className="container ">
            <h1 className="title has-text-centered">Galeries</h1>
          </div>
        </div>
      </div>
      <div className="section">
        <Covers
          couvertures={data.allStrapiGalerie.nodes.map(node => {
            return {
              key: node.strapiId,
              titre: node.Titre,
              image: node.Couverture.localFile.childImageSharp.gatsbyImageData,
              to: "/galerie/" + node.Slug,
            }
          })}
        />
      </div>
    </IndexLayout>
  )
}

export default GaleriesPage

export const pageQuery = graphql`
query GaleriesQuery {
  allStrapiGalerie(
    filter: {Visible: {eq: true}}
    sort: {fields: Position, order: ASC}
  ) {
    nodes {
      Couverture {
        localFile {
          childImageSharp {
            gatsbyImageData(
              layout: FIXED
              width: 800
              height: 800
              aspectRatio: 1
              jpgOptions: {progressive: true}
              transformOptions: {fit: COVER, cropFocus: CENTER}
              placeholder: BLURRED
            )
          }
        }
      }
      Titre
      Slug
      strapiId
    }
  }
}
`
