import React from "react"

import { Link } from "gatsby"

const Cover = ({ image, titre, to }) => {
  return (
    <Link to={to} className="pochette  column is-3">
      <img className="couverture" src={image.images.fallback.src} alt={"Galerie de "+titre} />
      <h1 className="titre">{titre}</h1>
    </Link>
  )
}

const Covers = ({ couvertures }) => {
  return (
    <div className="columns is-multiline ">
      {couvertures.map(({ key, image, titre, to }) => {
        return <Cover key={key} titre={titre} image={image} to={to} />
      })}
    </div>
  )
}

export default Covers
